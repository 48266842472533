import "./ChristingleChildrensSocietyBlock.css";

const ChristingleChildrensSocietyBlock = () => {
  return (
    <div id="christingle-block" className="christingle row parent m-0 p-0">
      <div className="col-md-12">
        <div className="p-5 row text-center">
          <a href={"https://givestar.io/gs/st-michaels-church-gordon-hill"}>
            <h2>
              Help us to support
              <br />The Children's Society
              <br />this Christingle</h2>
            <p>
              As we celebrate our Christingle service and Nativity play with the Children of St Michael's, help us to
              support the Children's Society with a donation to help them on their mission to help young people facing
              abuse, exploitation and neglect.
            </p>
            <p style={{ textDecoration: "underline" }}>
              Click here
              <br />to donate today!
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChristingleChildrensSocietyBlock;
