import { Route } from "react-router-dom"
import { createBrowserHistory } from 'history';

import "./App.css";

import PageHeader from "./components/page_header/PageHeader";
import PageFooter from "./components/page_footer/PageFooter";

import WelcomePage from "./pages/WelcomePage";
import WorshipPage from "./pages/WorshipPage";
import NoticeBoardPage from "./pages/NoticeBoardPage";
import ChildrenPage from "./pages/ChildrenPage";
import SafeguardingPage from "./pages/SafeguardingPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import CookiesPage from "./pages/CookiesPage";
import WhosWhoPage from "./pages/WhosWhoPage";
import SchoolAdmissionsPage from "./pages/SchoolAdmissionsPage";
// import FurnishingsAndVestmentsPage from "./pages/FurnishingsAndVestmentsPage";
import HistoryPage from "./pages/HistoryPage";
import SundayClubsPage from "./pages/SundayClubsPage";
// import CornerstonesPage from "./pages/CornerstonesPage";
// import ToddlerGroupPage from "./pages/ToddlerGroupPage";
// import GirlguidingPage from "./pages/GirlguidingPage";
import ChoirPage from "./pages/ChoirPage";
import ServingTeamPage from "./pages/ServingTeamPage";
import VenuesPage from "./pages/VenuesPage";
import CommunityAndOutreachPage from "./pages/CommunityAndOutreachPage";
import AutumnGroupPage from "./pages/AutumnGroupPage";
import PassiveGivingPage from "./pages/PassiveGivingPage";
import AmazonSmilePage from "./pages/AmazonSmilePage";
import EasyfundraisingPage from "./pages/EasyfundraisingPage";
import NewsPage from "./pages/NewsPage";
// import EventsPage from "./pages/EventsPage";
import ContactPage from "./pages/ContactPage";
import ContactPageThankYou from "./pages/ContactPageThankYou";
import FundraisingPage from "./pages/FundraisingPage";

import MainMenu from "./components/navigation/MainMenu";

export const history = createBrowserHistory();

function random_key_generator() {
    return Math.random();
}

function App() {
    // noinspection JSUnusedLocalSymbols
    return (
        <div className="App">
            <header className="site-header">
                <div className="container">
                    <PageHeader />
                    <Route path="/" render={(props) => <MainMenu key={random_key_generator()}/>} />
                </div>
            </header>
            <main className="main-content">
                <Route exact={true} path="/">
                    <WelcomePage />
                </Route>
                <Route path="/worship">
                    <WorshipPage />
                </Route>
                <Route exact={true} path="/news-and-events">
                    <NewsPage />
                </Route>
                <Route exact={true} path="/parish-news">
                    <NewsPage />
                </Route>
                {/*<Route exact={true} path="/events">*/}
                {/*    <EventsPage />*/}
                {/*</Route>*/}
                <Route path="/notice-board">
                    <NoticeBoardPage />
                </Route>
                <Route path="/childrens-clubs">
                    <SundayClubsPage />
                </Route>
                {/*<Route path="/cornerstones">*/}
                {/*    <CornerstonesPage />*/}
                {/*</Route>*/}
                {/*<Route path="/toddler-group">*/}
                {/*    <ToddlerGroupPage />*/}
                {/*</Route>*/}
                {/*<Route path="/girlguiding">*/}
                {/*    <GirlguidingPage />*/}
                {/*</Route>*/}
                <Route path="/choir">
                    <ChoirPage />
                </Route>
                <Route path="/serving-team">
                    <ServingTeamPage />
                </Route>
                <Route path="/community-and-outreach">
                    <CommunityAndOutreachPage />
                </Route>
                <Route path="/autumn-group">
                    <AutumnGroupPage />
                </Route>
                <Route path="/venues">
                    <VenuesPage />
                </Route>
                <Route path="/passive-giving">
                    <PassiveGivingPage />
                </Route>
                <Route path="/amazon-smile">
                    <AmazonSmilePage />
                </Route>
                <Route path="/easyfundraising">
                    <EasyfundraisingPage />
                </Route>
                <Route path="/whos-who">
                    <WhosWhoPage />
                </Route>
                <Route path="/school-admissions">
                    <SchoolAdmissionsPage />
                </Route>
                {/*<Route path="/furnishings-and-vestments">*/}
                {/*    <FurnishingsAndVestmentsPage />*/}
                {/*</Route>*/}
                <Route path="/history">
                    <HistoryPage />
                </Route>
                <Route path="/children">
                    <ChildrenPage />
                </Route>
                <Route exact={true} path="/contact">
                    <ContactPage />
                </Route>
                <Route path="/contact/thank-you">
                    <ContactPageThankYou />
                </Route>
                <Route path="/safeguarding">
                    <SafeguardingPage />
                </Route>
                <Route path="/privacy-policy">
                    <PrivacyPolicyPage />
                </Route>
                <Route path="/cookies">
                    <CookiesPage />
                </Route>

                {/* Special or hidden Pages: */}
                <Route path="/fundraising">
                    <FundraisingPage />
                </Route>
            </main>

            <PageFooter />
        </div>
    );
}

export default App;
